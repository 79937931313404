import React from 'react'
import { Link } from 'react-router-dom';

const Courses = () => {

  const courses = [
    {
      id: 1,
      type: 'Basic',
      price: 5999,
      name: 'Basics Vedic Jyotish course',
      content: '',
      duration: '2 Months',
      mode: 'Online',
      date: '',
      language: 'Hindi',
      image: 'https://res.cloudinary.com/dighdsaim/image/upload/v1717261948/Astro/z2e0jgohowdiggfi2pbp.jpg'
    },
    {
      id: 2,
      type: 'Advance',
      price: 11999,
      name: 'Advanced Vedic Jyotish Course',
      content: '',
      duration: '4 Months',
      mode: 'Online',
      date: '',
      language: '',
      image: 'https://res.cloudinary.com/dighdsaim/image/upload/v1717261948/Astro/z2e0jgohowdiggfi2pbp.jpg'
    },
    {
      id: 3,
      type: 'Professional',
      price: 14999,
      name: 'Professional Vedic Jyotish Course',
      content: '',
      duration: '6 Months',
      mode: 'Online',
      date: '',
      language: '',
      image: 'https://res.cloudinary.com/dighdsaim/image/upload/v1717261948/Astro/z2e0jgohowdiggfi2pbp.jpg'
    }
  ];
  return (
    <div className="container mx-auto p-4">
    <h1 className="text-2xl font-bold mb-4">Courses</h1>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {courses.map(course => (
        <Link to={`/course/${course.id}`} key={course.id} className="border p-4 rounded shadow hover:bg-gray-100">
          <img src={course.image} alt={course.name} className="w-full h-48 object-cover rounded mb-4" />
          <h2 className="text-xl font-bold">{course.name}</h2>
          <p><strong>Type:</strong> {course.type}</p>
          <p><strong>Price:</strong> ₹{course.price}</p>
          <p><strong>Duration:</strong> {course.duration}</p>
          <p><strong>Mode:</strong> {course.mode}</p>
          <p><strong>Date:</strong> {course.date}</p>
          <p><strong>Language:</strong> {course.language}</p>
        </Link>
      ))}
    </div>
  </div>
  )
}

export default Courses