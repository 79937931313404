import React from 'react'

const Banner = () => {
  return (
    <div>
        {/* <img src='https://res.cloudinary.com/dighdsaim/image/upload/v1718303095/bkouzwi2msoqpurfweg2.jpg' alt='img'/> */}
         <h2> Welcome to <span className='text-orange-600 font-bold'>Jivanbodh Vedic Jyotish Sansthan</span>. </h2>
        <section style={{background: "linear-gradient rgba(0,0,0,0.8) url"}}>

        </section>
    </div>
  )
}

export default Banner